<template>
  <el-card shadow="never" v-loading="searchForm.loading">
    <el-form :inline="true" :model="searchForm" style="height: 30px;">
      <el-form-item>
        <el-checkbox v-model="checked" label="Option 1" size="large" @change="changeSelect">全选</el-checkbox>
      </el-form-item>
      <el-form-item>
        <el-input v-model="searchForm.searchShop" placeholder="搜索门店" @keyup.enter="getShops" />
      </el-form-item>
      <el-form-item>
        <el-button @click="getShops">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-divider border-style="double" />
    <div class="shopsShow" v-if="shops.length !== 0">
      <el-checkbox-group v-model="selectStoreData" @change="handleCheckedCitiesChange">
        <el-checkbox
          :title="item.label"
          :label="item.value"
          size="large"
          style="margin-right: 10px; margin-left:0px"
          border
          v-for="(item, index) in shops"
          :key="index"
          >{{ item.label }}</el-checkbox
        >
      </el-checkbox-group>
    </div>
    <el-empty description="当前区域暂无门店，请重新选择区域" :image-size="200" v-else />
  </el-card>
</template>
<script setup>
import commonApi from '@/api'
import API from '../service/api'
import { onMounted, reactive, ref, toRaw, defineEmit, watchEffect, defineProps } from 'vue'
const props = defineProps({
  selectStoreData: {
    type: Array,
    default: () => [],
  },
})
const emit = defineEmit(['changeStore'])
const searchForm = reactive({
  loading: false,
  checked: false,
  geoCode: [],
  shopLists: [],
  optionsProps: {
    label: 'name',
    children: 'child',
    value: 'code',
    checkStrictly: true,
  },
  searchShop: '',
})

const shops = ref([])

onMounted(() => {
  getShops()
  getArea()
})

watchEffect(() => {
  emit('changeStore', props.selectStoreData || [])
})
const handleCheckedCitiesChange = item => {
  emit('changeStore', item)
}
//  获取门店
const getShops = () => {
  searchForm.loading = true
  let params = {
    name: searchForm.searchShop,
  }
  if (Array.isArray(toRaw(searchForm.geoCode))) {
    params.geoCode = searchForm.geoCode[searchForm.geoCode.length - 1]
  }
  API.getStoreList(params)
    .then(res => {
      if (res.code === '200') {
        searchForm.checked = false
        searchForm.loading = false
        shops.value = res.data.map(item => ({
          label: item.name,
          value: item.code,
        }))
      } else {
        ElMessage.error(res.msg)
      }
    })
    .finally(() => (searchForm.loading = false))
}

//获取门店区域
const getArea = () => {
  const getTreeData = data => {
    for (var i = 0; i < data.length; i++) {
      if (data[i].children.length < 1) {
        // children若为空数组，则将children设为undefined
        data[i].children = undefined
      } else {
        // children若不为空数组，则继续 递归调用 本方法
        getTreeData(data[i].children)
      }
    }
    return data
  }
  commonApi.getNewArea().then(res => {
    if (res.code === '200') {
      searchForm.shopLists = getTreeData(res.data)
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const changeSelect = a => {
  if (a) {
    emit(
      'changeStore',
      shops.value.map(item => item.value),
    )
  } else {
    // props.selectStoreData = []
    emit('changeStore', [])
  }
}
</script>

<style scoped lang="scss">
.shopsShow {
  display: flex;
  flex-wrap: wrap;
  max-height: 300px;
  overflow-y: auto;
}
::v-deep(.el-checkbox-group) {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
::v-deep(.el-checkbox) {
  display: flex;
  align-items: center;
  width: min-content;
  margin-bottom: 10px;
  .el-checkbox__label {
    width: 140px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
